.component-head{
    margin-bottom: 20px;
    .component-header{
        .component-header-btn{
            background-color: #00a2ed;
            color: #fff;
            box-shadow: none;
            border: 0;
            cursor: pointer;
            margin-left: 10px;
            display: inline-flex;
            outline: 0;
            position: relative;
            align-items: center;
            user-select: none;
            vertical-align: middle;
            justify-content: center;
            text-decoration: none;
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            padding: 6px 16px;
            font-size: 0.875rem;
            min-width: 64px;
            box-sizing: border-box;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            line-height: 1.75;
            border-radius: 4px;
            letter-spacing: 0.02857em;
            text-transform: uppercase;

            &:hover{
                background-color: rgb(17, 82, 147);
            }
        }
    }
}