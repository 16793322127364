.alert{
    display: flex;
    padding: 6px 16px;
    margin: 10px 0;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    background-color: transparent;
    
    &.alert-light{
        color: #818182;
        background-color: #fbf2f2;
        border-color: #fdfdfe;
    }

    &.alert-info{
        a{
            color: #f1ebeb;
            border-bottom: 1px dashed #fff;

            &:hover{
                text-decoration: none;
                border-bottom: 1px solid #fff;
            }
        }
    }

    &.alert-danger{
        color: #fff;
        font-weight: 500;
        background-color: #f44336;
    }

    &.alert-warning{
        color: #fff;
        font-weight: 500;
        background-color: #ff9800;
    }

    &.alert-info{
        color: #fff;
        font-weight: 500;
        background-color: #2196f3;
    }

    &.alert-success{
        color: #fff;
        font-weight: 500;
        background-color: #4caf50;
    }

    &.align-left{
        text-align: left;
    }

    &.align-right{
        text-align: right;
    }

    &.align-center{
        text-align: center;
    }
}

.MuiAlert-icon {
    display: flex;
    opacity: 0.9;
    padding: 7px 0;
    font-size: 22px;
    margin-right: 12px;
}

.MuiAlert-message {
    padding: 8px 0;
}