.navbar-drawer{
    a{ 
        display: block;
        text-decoration: none;
        color: inherit;

        &.active{
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}