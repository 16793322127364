.quick-menu{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;

    &.variant-1{
        border: 1px solid #bdbdbd;
        background: #fffcfc;
        color: #000;
        font-weight: 400;

        &:hover{
            border: 1px solid #56b4da;
            color: #000;
        }
    }

    &.variant-2{
        background-color: #00a2ed;
        color: #fff;
    }

    a{
        display: block;
        text-decoration: none;
        color: inherit;
    }

    .quick-menu-content{
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
    }

    .quick-menu-icon{
        font-size: 100px;
        color: #928e8e;
    }
}